/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation UpdateProviderAPIKey($data: CreateProviderApiKeyInput!) {\n    updateProviderApiKey(data: $data) {\n      provider\n    }\n  }\n": types.UpdateProviderApiKeyDocument,
    "\n  mutation DeleteProviderAPIKey($data: DeleteProviderApiKeyInput!) {\n    deleteProviderApiKey(data: $data) {\n      provider\n    }\n  }\n": types.DeleteProviderApiKeyDocument,
    "\n  mutation CreateEnvironment($data: CreateEnvironmentInput!) {\n    createEnvironment(data: $data) {\n      name\n    }\n  }\n": types.CreateEnvironmentDocument,
    "\n  mutation DeleteEnvironment($data: EnvironmentWhereUniqueInput!) {\n    deleteEnvironment(data: $data) {\n      id\n    }\n  }\n": types.DeleteEnvironmentDocument,
    "\n  mutation DeleteInvitation($data: InvitationWhereUniqueInput!) {\n    deleteOrgInvitation(data: $data) {\n      id\n    }\n  }\n": types.DeleteInvitationDocument,
    "\n  mutation AcceptInvitation($data: InvitationWhereUniqueInput!) {\n    acceptOrgInvitation(data: $data) {\n      id\n      name\n    }\n  }\n": types.AcceptInvitationDocument,
    "\n  mutation CreateOrgInvitation($data: CreateOrgInvitationInput!) {\n    createOrgInvitation(data: $data) {\n      id\n    }\n  }\n": types.CreateOrgInvitationDocument,
    "\n  mutation UpdateOrgInvitation($data: UpdateOrgInvitationInput!) {\n    updateOrgInvitation(data: $data) {\n      id\n      role\n    }\n  }\n": types.UpdateOrgInvitationDocument,
    "\n  mutation DeleteOrgMember($data: OrganizationMemberWhereUniqueInput!) {\n    deleteOrgMember(data: $data) {\n      id\n    }\n  }\n": types.DeleteOrgMemberDocument,
    "\n  mutation UpdateOrgMemberRole($data: UpdateOrgMemberRoleInput!) {\n    updateOrgMemberRole(data: $data) {\n      role\n    }\n  }\n": types.UpdateOrgMemberRoleDocument,
    "\n  mutation UpdateOrgSettings($data: UpdateOrgSettingsInput!) {\n    updateOrgSettings(data: $data) {\n      name\n    }\n  }\n": types.UpdateOrgSettingsDocument,
    "\n  mutation createProject($data: CreateProjectInput!) {\n    createProject(data: $data) {\n      id\n      organizationId\n      name\n    }\n  }\n": types.CreateProjectDocument,
    "\n  mutation deleteProject($data: ProjectWhereUniqueInput!) {\n    deleteProject(data: $data) {\n      id\n    }\n  }\n": types.DeleteProjectDocument,
    "\n  mutation updateProjectSettings($data: UpdateProjectSettingsInput!) {\n    updateProjectSettings(data: $data) {\n      id\n    }\n  }\n": types.UpdateProjectSettingsDocument,
    "\n  mutation PublishPrompt($data: PublishPromptInput!) {\n    publishPrompt(data: $data) {\n      promptId\n    }\n  }\n": types.PublishPromptDocument,
    "\n  mutation createPrompt($data: CreatePromptInput!) {\n    createPrompt(data: $data) {\n      id\n    }\n  }\n": types.CreatePromptDocument,
    "\n  mutation createPromptVersion($data: CreatePromptVersionInput!) {\n    createPromptVersion(data: $data) {\n      sha\n    }\n  }\n": types.CreatePromptVersionDocument,
    "\n  mutation deletePrompt($data: PromptWhereUniqueInput!) {\n    deletePrompt(data: $data) {\n      id\n    }\n  }\n": types.DeletePromptDocument,
    "\n  query ProviderApiKeys($data: GetProviderApiKeysInput!) {\n    providerApiKeys(data: $data) {\n      id\n      provider\n      censoredValue\n    }\n  }\n": types.ProviderApiKeysDocument,
    "\n  query ApiKeys($data: GetApiKeysInput!) {\n    apiKeys(data: $data) {\n      id\n    }\n  }\n": types.ApiKeysDocument,
    "\n  query Environments($data: GetEnvironmentsInput!) {\n    environments(data: $data) {\n      id\n      name\n    }\n  }\n": types.EnvironmentsDocument,
    "\n  query getGenericProjectMetricHistogram(\n    $data: GetProjectGenericHistogramInput!\n  ) {\n    genericProjectMetricHistogram(data: $data) {\n      data\n    }\n  }\n": types.GetGenericProjectMetricHistogramDocument,
    "\n  query getProjectMetricDelta($data: GetProjectMetricDeltaInput!) {\n    projectMetricDelta(data: $data) {\n      currentValue\n      previousValue\n    }\n  }\n": types.GetProjectMetricDeltaDocument,
    "\n  query GetMyOrganizations {\n    organizations {\n      id\n      name\n    }\n  }\n": types.GetMyOrganizationsDocument,
    "\n  query GetOrg(\n    $data: OrganizationWhereUniqueInput!\n    $includeInvitations: Boolean = false\n    $includeMembers: Boolean = true\n  ) {\n    organization(data: $data) {\n      id\n      name\n      waitlisted\n      members @include(if: $includeMembers) {\n        id\n        role\n        user {\n          id\n          name\n          email\n        }\n      }\n      invitations @include(if: $includeInvitations) {\n        id\n        email\n        role\n        invitedBy {\n          photoUrl\n        }\n      }\n    }\n  }\n": types.GetOrgDocument,
    "\n  query GetOrgMembership($data: GetUserOrgMembershipInput!) {\n    userOrgMembership(data: $data) {\n      userId\n      role\n      organizationId\n    }\n  }\n": types.GetOrgMembershipDocument,
    "\n  query getProject($data: ProjectWhereUniqueInput!) {\n    project(data: $data) {\n      id\n      slug\n      name\n      organization {\n        id\n        name\n      }\n    }\n  }\n": types.GetProjectDocument,
    "\n  query getProjects($data: GetProjectsInput!) {\n    projects(data: $data) {\n      id\n      slug\n      name\n      organizationId\n    }\n  }\n": types.GetProjectsDocument,
    "\n  mutation testPrompt($data: TestPromptInput!) {\n    testPrompt(data: $data)\n  }\n": types.TestPromptDocument,
    "\n  query getAllPrompts($data: GetProjectPromptsInput!) {\n    prompts(data: $data) {\n      id\n      name\n      isDraft\n    }\n  }\n": types.GetAllPromptsDocument,
    "\n  query getPrompt($data: GetPromptInput!) {\n    prompt(data: $data) {\n      id\n      name\n      isDraft\n      latestVersion {\n        sha\n        message\n        createdBy {\n          name\n          photoUrl\n        }\n      }\n    }\n  }\n": types.GetPromptDocument,
    "\n  query getPromptVersion($data: PromptVersionWhereUniqueInput!) {\n    promptVersion(data: $data) {\n      sha\n      type\n      service\n      content\n      settings\n      message\n    }\n  }\n": types.GetPromptVersionDocument,
    "\n  query GetPromptVersionsWithTags($data: GetPromptInput!) {\n    prompt(data: $data) {\n      versions {\n        type\n        sha\n        service\n        message\n        createdAt\n        createdBy {\n          name\n          photoUrl\n          email\n        }\n      }\n    }\n  }\n": types.GetPromptVersionsWithTagsDocument,
    "\n  query PaginatedRequests($data: GetRequestsInput!) {\n    paginatedRequests(data: $data) {\n      data\n      pagination {\n        offset\n        limit\n        total\n      }\n    }\n  }\n": types.PaginatedRequestsDocument,
    "\n  query GetReport($data: GetReportInput!) {\n    report(data: $data)\n  }\n": types.GetReportDocument,
    "\n  query GetMe {\n    me {\n      id\n      email\n      photoUrl\n      name\n      organizationIds\n    }\n  }\n": types.GetMeDocument,
    "\n  mutation UpdateProfile($data: UpdateProfileInput!) {\n    updateProfile(data: $data) {\n      name\n    }\n  }\n": types.UpdateProfileDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateProviderAPIKey($data: CreateProviderApiKeyInput!) {\n    updateProviderApiKey(data: $data) {\n      provider\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateProviderAPIKey($data: CreateProviderApiKeyInput!) {\n    updateProviderApiKey(data: $data) {\n      provider\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteProviderAPIKey($data: DeleteProviderApiKeyInput!) {\n    deleteProviderApiKey(data: $data) {\n      provider\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteProviderAPIKey($data: DeleteProviderApiKeyInput!) {\n    deleteProviderApiKey(data: $data) {\n      provider\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateEnvironment($data: CreateEnvironmentInput!) {\n    createEnvironment(data: $data) {\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation CreateEnvironment($data: CreateEnvironmentInput!) {\n    createEnvironment(data: $data) {\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteEnvironment($data: EnvironmentWhereUniqueInput!) {\n    deleteEnvironment(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteEnvironment($data: EnvironmentWhereUniqueInput!) {\n    deleteEnvironment(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteInvitation($data: InvitationWhereUniqueInput!) {\n    deleteOrgInvitation(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteInvitation($data: InvitationWhereUniqueInput!) {\n    deleteOrgInvitation(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AcceptInvitation($data: InvitationWhereUniqueInput!) {\n    acceptOrgInvitation(data: $data) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation AcceptInvitation($data: InvitationWhereUniqueInput!) {\n    acceptOrgInvitation(data: $data) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateOrgInvitation($data: CreateOrgInvitationInput!) {\n    createOrgInvitation(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateOrgInvitation($data: CreateOrgInvitationInput!) {\n    createOrgInvitation(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateOrgInvitation($data: UpdateOrgInvitationInput!) {\n    updateOrgInvitation(data: $data) {\n      id\n      role\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateOrgInvitation($data: UpdateOrgInvitationInput!) {\n    updateOrgInvitation(data: $data) {\n      id\n      role\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteOrgMember($data: OrganizationMemberWhereUniqueInput!) {\n    deleteOrgMember(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteOrgMember($data: OrganizationMemberWhereUniqueInput!) {\n    deleteOrgMember(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateOrgMemberRole($data: UpdateOrgMemberRoleInput!) {\n    updateOrgMemberRole(data: $data) {\n      role\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateOrgMemberRole($data: UpdateOrgMemberRoleInput!) {\n    updateOrgMemberRole(data: $data) {\n      role\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateOrgSettings($data: UpdateOrgSettingsInput!) {\n    updateOrgSettings(data: $data) {\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateOrgSettings($data: UpdateOrgSettingsInput!) {\n    updateOrgSettings(data: $data) {\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createProject($data: CreateProjectInput!) {\n    createProject(data: $data) {\n      id\n      organizationId\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation createProject($data: CreateProjectInput!) {\n    createProject(data: $data) {\n      id\n      organizationId\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteProject($data: ProjectWhereUniqueInput!) {\n    deleteProject(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation deleteProject($data: ProjectWhereUniqueInput!) {\n    deleteProject(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateProjectSettings($data: UpdateProjectSettingsInput!) {\n    updateProjectSettings(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation updateProjectSettings($data: UpdateProjectSettingsInput!) {\n    updateProjectSettings(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation PublishPrompt($data: PublishPromptInput!) {\n    publishPrompt(data: $data) {\n      promptId\n    }\n  }\n"): (typeof documents)["\n  mutation PublishPrompt($data: PublishPromptInput!) {\n    publishPrompt(data: $data) {\n      promptId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createPrompt($data: CreatePromptInput!) {\n    createPrompt(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation createPrompt($data: CreatePromptInput!) {\n    createPrompt(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createPromptVersion($data: CreatePromptVersionInput!) {\n    createPromptVersion(data: $data) {\n      sha\n    }\n  }\n"): (typeof documents)["\n  mutation createPromptVersion($data: CreatePromptVersionInput!) {\n    createPromptVersion(data: $data) {\n      sha\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deletePrompt($data: PromptWhereUniqueInput!) {\n    deletePrompt(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation deletePrompt($data: PromptWhereUniqueInput!) {\n    deletePrompt(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ProviderApiKeys($data: GetProviderApiKeysInput!) {\n    providerApiKeys(data: $data) {\n      id\n      provider\n      censoredValue\n    }\n  }\n"): (typeof documents)["\n  query ProviderApiKeys($data: GetProviderApiKeysInput!) {\n    providerApiKeys(data: $data) {\n      id\n      provider\n      censoredValue\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ApiKeys($data: GetApiKeysInput!) {\n    apiKeys(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  query ApiKeys($data: GetApiKeysInput!) {\n    apiKeys(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Environments($data: GetEnvironmentsInput!) {\n    environments(data: $data) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query Environments($data: GetEnvironmentsInput!) {\n    environments(data: $data) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getGenericProjectMetricHistogram(\n    $data: GetProjectGenericHistogramInput!\n  ) {\n    genericProjectMetricHistogram(data: $data) {\n      data\n    }\n  }\n"): (typeof documents)["\n  query getGenericProjectMetricHistogram(\n    $data: GetProjectGenericHistogramInput!\n  ) {\n    genericProjectMetricHistogram(data: $data) {\n      data\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getProjectMetricDelta($data: GetProjectMetricDeltaInput!) {\n    projectMetricDelta(data: $data) {\n      currentValue\n      previousValue\n    }\n  }\n"): (typeof documents)["\n  query getProjectMetricDelta($data: GetProjectMetricDeltaInput!) {\n    projectMetricDelta(data: $data) {\n      currentValue\n      previousValue\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMyOrganizations {\n    organizations {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query GetMyOrganizations {\n    organizations {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetOrg(\n    $data: OrganizationWhereUniqueInput!\n    $includeInvitations: Boolean = false\n    $includeMembers: Boolean = true\n  ) {\n    organization(data: $data) {\n      id\n      name\n      waitlisted\n      members @include(if: $includeMembers) {\n        id\n        role\n        user {\n          id\n          name\n          email\n        }\n      }\n      invitations @include(if: $includeInvitations) {\n        id\n        email\n        role\n        invitedBy {\n          photoUrl\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetOrg(\n    $data: OrganizationWhereUniqueInput!\n    $includeInvitations: Boolean = false\n    $includeMembers: Boolean = true\n  ) {\n    organization(data: $data) {\n      id\n      name\n      waitlisted\n      members @include(if: $includeMembers) {\n        id\n        role\n        user {\n          id\n          name\n          email\n        }\n      }\n      invitations @include(if: $includeInvitations) {\n        id\n        email\n        role\n        invitedBy {\n          photoUrl\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetOrgMembership($data: GetUserOrgMembershipInput!) {\n    userOrgMembership(data: $data) {\n      userId\n      role\n      organizationId\n    }\n  }\n"): (typeof documents)["\n  query GetOrgMembership($data: GetUserOrgMembershipInput!) {\n    userOrgMembership(data: $data) {\n      userId\n      role\n      organizationId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getProject($data: ProjectWhereUniqueInput!) {\n    project(data: $data) {\n      id\n      slug\n      name\n      organization {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query getProject($data: ProjectWhereUniqueInput!) {\n    project(data: $data) {\n      id\n      slug\n      name\n      organization {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getProjects($data: GetProjectsInput!) {\n    projects(data: $data) {\n      id\n      slug\n      name\n      organizationId\n    }\n  }\n"): (typeof documents)["\n  query getProjects($data: GetProjectsInput!) {\n    projects(data: $data) {\n      id\n      slug\n      name\n      organizationId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation testPrompt($data: TestPromptInput!) {\n    testPrompt(data: $data)\n  }\n"): (typeof documents)["\n  mutation testPrompt($data: TestPromptInput!) {\n    testPrompt(data: $data)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAllPrompts($data: GetProjectPromptsInput!) {\n    prompts(data: $data) {\n      id\n      name\n      isDraft\n    }\n  }\n"): (typeof documents)["\n  query getAllPrompts($data: GetProjectPromptsInput!) {\n    prompts(data: $data) {\n      id\n      name\n      isDraft\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPrompt($data: GetPromptInput!) {\n    prompt(data: $data) {\n      id\n      name\n      isDraft\n      latestVersion {\n        sha\n        message\n        createdBy {\n          name\n          photoUrl\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getPrompt($data: GetPromptInput!) {\n    prompt(data: $data) {\n      id\n      name\n      isDraft\n      latestVersion {\n        sha\n        message\n        createdBy {\n          name\n          photoUrl\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPromptVersion($data: PromptVersionWhereUniqueInput!) {\n    promptVersion(data: $data) {\n      sha\n      type\n      service\n      content\n      settings\n      message\n    }\n  }\n"): (typeof documents)["\n  query getPromptVersion($data: PromptVersionWhereUniqueInput!) {\n    promptVersion(data: $data) {\n      sha\n      type\n      service\n      content\n      settings\n      message\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPromptVersionsWithTags($data: GetPromptInput!) {\n    prompt(data: $data) {\n      versions {\n        type\n        sha\n        service\n        message\n        createdAt\n        createdBy {\n          name\n          photoUrl\n          email\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPromptVersionsWithTags($data: GetPromptInput!) {\n    prompt(data: $data) {\n      versions {\n        type\n        sha\n        service\n        message\n        createdAt\n        createdBy {\n          name\n          photoUrl\n          email\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PaginatedRequests($data: GetRequestsInput!) {\n    paginatedRequests(data: $data) {\n      data\n      pagination {\n        offset\n        limit\n        total\n      }\n    }\n  }\n"): (typeof documents)["\n  query PaginatedRequests($data: GetRequestsInput!) {\n    paginatedRequests(data: $data) {\n      data\n      pagination {\n        offset\n        limit\n        total\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetReport($data: GetReportInput!) {\n    report(data: $data)\n  }\n"): (typeof documents)["\n  query GetReport($data: GetReportInput!) {\n    report(data: $data)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMe {\n    me {\n      id\n      email\n      photoUrl\n      name\n      organizationIds\n    }\n  }\n"): (typeof documents)["\n  query GetMe {\n    me {\n      id\n      email\n      photoUrl\n      name\n      organizationIds\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateProfile($data: UpdateProfileInput!) {\n    updateProfile(data: $data) {\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateProfile($data: UpdateProfileInput!) {\n    updateProfile(data: $data) {\n      name\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;